import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/Home';
import Navigation from "./components/Navigation";
import TU from "./components/TU";

function App() {
  return (
      <BrowserRouter>
      <Navigation/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='tu' element={<TU />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
