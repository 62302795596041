import React from 'react';

import '../css/Navigation.css'

export default function Navigation () {
    return <div className='navigation'>
        <a className='nav-link' target='_blank' rel='noreferrer' href='https://proxm.nikola-voislav.com/'>Proxmox</a>
        <a className='nav-link' target='_blank' rel='noreferrer' href='https://photos.nikola-voislav.com/'>Photo Prism</a>
        <a className='nav-link' target='_blank' rel='noreferrer' href='https://app.plex.tv/'>Plex App</a>
        <a className='nav-link' target='_blank' rel='noreferrer' href='https://request.nikola-voislav.com/'>Request A Movie</a>
        {/*<Link className='nav-link' to='/'>Tu-Archive New (WIP)</Link>*/}
    </div>;
}