import React, { useEffect } from 'react';

export default function Home (){

    useEffect(() => {
        document.title = 'Portal';
        document.body.style.backgroundImage = `url(https://picsum.photos/${window.screen.width}/${window.screen.height})`
    }, []);

    return (
        <div>
        </div>
    )
}